
import Vue from "vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MainLayout",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      isTopLoading: true,
      isProfileLoaded: true
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      colorSettings: "helper/getColorSettings",
      landing_page: "helper/getLandingPage"
    }),
    headerStyle(): any {
      if (
        this.colorSettings["header_background"] &&
        this.colorSettings["enable_color_customization"] == 1
      ) {
        return `background:${this.colorSettings["header_background"]}`;
      } else if (
        this.$route.name === "LandingPage" &&
        this.landing_page.header_color
      ) {
        return `background:${this.landing_page.header_color}`;
      }

      return null;
    },
    footerStyle(): any {
      if (
        this.colorSettings["footer_background"] &&
        this.colorSettings["enable_color_customization"] == 1
      ) {
        return `background:${this.colorSettings["footer_background"]}`;
      } else if (
        this.$route.name === "LandingPage" &&
        this.landing_page.footer_color
      ) {
        return `background:${this.landing_page.footer_color}`;
      }
      return null;
    }
  },
  watch: {
    $route() {
      this.isTopLoading = true;
      setTimeout(() => (this.isTopLoading = false), 2000);
    }
  },
  mounted() {
    this.isTopLoading = false;

    window.addEventListener("resetPage", this.resetPage);
  },
  beforeDestroy() {
    window.removeEventListener("resetPage", this.resetPage);
  },
  methods: {
    setLoading(bool: boolean) {
      this.isProfileLoaded = bool;
    },
    resetPage() {
      this.isProfileLoaded = true;
      this.$nextTick(() => {
        this.isProfileLoaded = false;
      });
    }
  }
});
